import Global_Table_Config from "./common_func";

if (front_end_data.isProActive) {
    jQuery(document).ready(function ($) {
        class Data_Tables_Async {
            constructor() {
                this.frontend_table = $(".gswpts_tables_container");

                if (front_end_data.asynchronous_loading == "on") {
                    this.events();
                } else {
                    return;
                }
            }
            events() {
                this.get_frontend_table();
            }

            get_frontend_table() {
                $.each(this.frontend_table, function (i, elem) {
                    let id = $(elem).attr("id");
                    $.ajax({
                        url  : front_end_data.admin_ajax,
                        data : {
                            action : "gswpts_sheet_fetch",
                            id     : id,
                            nonce  : $(this).data('nonce')
                        },
                        type: "post",
                        success: (res) => {
                            let tableClass = new Global_Table_Config();
                            let table_settings = res.data.table_data.table_settings;

                            if (tableClass.isProPluginActive()) {
                                if (table_settings.responsive_style) {
                                    $(elem).addClass(table_settings.responsive_style);
                                }

                                if (table_settings.import_styles == "true") {
                                    $(elem).addClass(`gswpts_default-style`);
                                } else {
                                    $(elem).addClass(
                                        `gswpts_${
                                            table_settings.table_style
                                                ? table_settings.table_style
                                                : "default-style"
                                        }`
                                    );
                                }

                                $(elem).addClass(`gswpts_default-style`);
                            }

                            if (table_settings.table_title == "true") {
                                $(elem).find("h3").html(res.data.table_data.table_name);
                            }

                            $(elem).find(".gswpts_tables_content").html(res.data.output);

                            let table_name = res.data.table_data.table_name;
                            let sheetUrl   = res.data.table_data.source_url;

                            tableClass.table_configuration(
                                $,
                                i,
                                elem,
                                table_name,
                                table_settings,
                                sheetUrl
                            );

                            let scrollerContainer = $(elem).find(".dataTables_scroll");
                            let scrollerElement = $(elem).find(".dataTables_scrollBody");

                            // Add functionality of scrolling the table.
                            tableClass.bindDragScroll(scrollerContainer, scrollerElement);
                            tableClass.addGrabCursonOnMouseDown($(elem).find("#create_tables"));

                            tableClass.clearOverflow();
                        },
                        error: (err) => {
                            alert("Something went wrong");
                            console.error(err);
                            $(elem)
                                .find(".gswpts_tables_content")
                                .html("<strong>Table could not be loaded. Try again</strong>");
                        },
                    });
                });
            }
        }

        new Data_Tables_Async();
    });
} else {
    jQuery(document).ready(function ($) {
        class Data_Tables_Async {
            constructor() {
                this.frontend_table = $(".gswpts_tables_container");
                if (front_end_data.asynchronous_loading == "on") {
                    this.events();
                } else {
                    return;
                }
            }
            events() {
                this.get_frontend_table();
            }
            get_frontend_table() {
                $.each(this.frontend_table, function (i, elem) {
                    let id = $(elem).attr("id");

                    $.ajax({
                        url: front_end_data.admin_ajax,
                        data: {
                            action: "gswpts_sheet_fetch",
                            id: id,
                            nonce: $(this).data('nonce')
                        },
                        type: "post",
                        success: (res) => {
                            let table_settings = res.data.table_data.table_settings;

                            if (table_settings.responsive_style) {
                                $(elem).addClass(table_settings.responsive_style);
                            }

                            if (table_settings.table_title == "true") {
                                $(elem).find("h3").html(res.data.table_data.table_name);
                            }

                            $(elem).find(".gswpts_tables_content").html(res.data.output);

                            let table_name = res.data.table_data.table_name;
                            let sheetUrl   = res.data.table_data.source_url;

                            let tableClass = new Global_Table_Config();

                            tableClass.table_configuration(
                                $,
                                i,
                                elem,
                                table_name,
                                table_settings,
                                sheetUrl
                            );

                            tableClass.clearOverflow();
                        },
                        error: (err) => {
                            alert("Something went wrong");
                            console.error(err);
                            $(elem)
                                .find(".gswpts_tables_content")
                                .html("<strong>Table could not be loaded. Try again</strong>");
                        },
                    });
                });
            }
        }

        new Data_Tables_Async();
    });
}
